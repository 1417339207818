import React, { useEffect } from "react"
import { AppProvider, Frame, Loading, Page } from "@shopify/polaris"

import { ReactifyApp } from "@reactify-apps/reactify-core-app"

const ReauthPage = () => {
  useEffect(() => {
    ReactifyApp.Auth.refreshAuth()
  }, [])

  return (
    <AppProvider>
      <Page>
        <Frame>
          <Loading />
        </Frame>
      </Page>
    </AppProvider>
  )
}

export default ReauthPage
